import React from 'react';
import style from './index.module.scss';

import Terminal from '../../components/Terminal'

import lock from '../../assets/lock.svg'

type CryptoPageProps = {}
type CryptoPageState = {}

class CryptoPage extends React.Component<CryptoPageProps, CryptoPageState> {
  render() {
    return (
      <div className={style.CryptoPage}>
        <div
          className={style.HomeButton}
          onClick={() => { window.location.href = '/' }}
          >
          {'< HOME'}
        </div>
        <div className={style.CryptoContainer}>
          <div className={style.CryptoTitle}>
            Crypto
          </div>
          <div className={style.CryptoTerminal}>
            <img src={lock} width={100} />
          </div>
          <div className={style.CryptoSummary}>
            Fundamentals...
          </div>
          <div className={style.CryptoMenuTitle}>
            <div>
              Private Key
            </div>
          </div>
          <div className={style.CryptoMenu}>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              1. What is a Private Key?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              2. How do I make a Private Key?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              3. What makes a Private Key?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              4. What can I use a Private Key for?
            </div>
          </div>
          <div className={style.CryptoMenuTitle}>
            <div>
              Public Key
            </div>
          </div>
          <div className={style.CryptoMenu}>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              1. What is a Public Key?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              2. How do I make a Public Key?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_private_key' }}>
              2. What can I use a Public Key for?
            </div>
          </div>
          <div className={style.CryptoMenuTitle}>
            <div>
              Wallet
            </div>
          </div>
          <div className={style.CryptoMenu}>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_wallet' }}>
              1. What is a Wallet?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_wallet' }}>
              1. Wallet vs a Wallet Address?
            </div>
            <div
              className={style.CryptoSelect}
              onClick={() => { window.location.href = '/crypto/what_is_a_wallet' }}>
              2. How do I make a Wallet/Wallet Address?
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CryptoPage;
