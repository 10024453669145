import React from 'react';
import style from './index.module.scss';

type PrivateKeyPageProps = {}
type PrivateKeyPageState = {}

class PrivateKeyPage extends React.Component<PrivateKeyPageProps, PrivateKeyPageState> {
  render() {
    return (
      <div className={style.PrivateKeyPage}>
        <div
          className={style.BackButton}
          onClick={() => { window.location.href = '/crypto' }}
          >
          {'< CRYPTO'}
        </div>
        <div className={style.PrivateKeyContainer}>
          <div className={style.PrivateKeyTitle}>
            What is a Private Key?
          </div>
          <div className={style.PrivateKeySummary}>
            A randomly generated sequence of bits used as a key in a cryptography function
          </div>
          <div className={style.PrivateKeyTitle}>
            How do you make a Private Key?
          </div>
          <div className={style.PrivateKeySummary}>
            Pick a number between 0 - 10. Great you have yourself a private key! The problem is that it isn't very secure.
          </div>
        </div>
      </div>
    )
  }
}

export default PrivateKeyPage