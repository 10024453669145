import React from 'react';
import style from './index.module.scss';

import Terminal from '../../components/Terminal'

import Pig from '../../assets/image.png'
import Heart from '../../assets/heart.jpg'
import Twitter from '../../assets/twitter.png'

type HomePageProps = {}
type HomePageState = {}

class HomePage extends React.Component<HomePageProps, HomePageState> {
  render() {
    return (
      <div className={style.HomePage}>
        <div className={style.DOS}>
          <div className={style.Line}>
            Starting MS-DOS<span className={style.DotOne}>.</span><span className={style.DotTwo}>.</span><span className={style.DotThree}>.</span>
          </div>
          <div className={`${style.Line} ${style.Loaded}`}>
            {"C:\\>"}
          </div>
        </div>
        <div className={style.Content}>
          <div className={style.ContentTitle}>
            <div className={style.Rainbow}>ROMI.BTC</div> <div className={style.ContentSubtitle}>* EMPORIUM</div>
          </div>
          <div className={style.ContentBody}>
            <img src={Pig} width={100} height={100}/>
          </div>
          {/*<div className={style.ContextStatement}>
            <div>Understanding reality from principles.</div>
            <div className={style.ContextStatementLine}><div>Dev at </div><img width={24} height={24} src={Heart} /></div>
          </div>
          <div className={style.ContentMenuTitle}>
            <div>
              MUSINGS
            </div>
          </div>
          <div className={style.ContentMenu}>
            <div className={style.ContentSelect}>
              Economics
            </div>
          </div>*/}
          <div className={style.ContentMenuTitle}>
            <div>
              LIFE TRACKING
            </div>
          </div>
          <div className={style.ContentMenu}>
            <div
              className={style.ContentSelect}
              onClick={() => { window.location.href = '/life/food'}}>
              Food Intake
            </div>
          </div>
          {/*<div className={style.ContentMenuTitle}>
            <div>
              AN INTRO TO...
            </div>
          </div>
          <div className={style.ContentMenu}>*/}
            {/*<div className={style.ContentSelect} onClick={() => { window.location.href = '/cpp'}}>
              C++
            </div>
            <div className={style.ContentSelect} onClick={() => { window.location.href = '/crypto'}}>
              Crypto
            </div>*/}
           {/* <div className={style.ContentSelect}>
              Rust
            </div>
            <div className={style.ContentSelect}>
              Javascript
            </div>
            <div className={style.ContentSelect}>
              Embedded
            </div>
            <div className={style.ContentSelect}>
              PCB's
            </div>*/}
         {/* </div>*/}
          <div className={style.ContentMenuTitle}>
            <div>
              FIND ME ON
            </div>
          </div>
          <div className={style.ContentMenu} onClick={() => {window.location.href = 'https://twitter.com/RomiEklund'}}>
            <img src={Twitter} height={24}/>
          </div>
        </div>
      </div>
    )
  }
}

export default HomePage