import React from 'react';
import style from './App.module.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import ErrorPage from './pages/ErrorPage'
import HomePage from './pages/HomePage'

import CppPage from './pages/CppPage'

import SimpleProgram from './pages/lessons/CPP/SimpleProgram'
import Functions from './pages/lessons/CPP/Functions'
import Variables from './pages/lessons/CPP/Variables'

import CryptoPage from './pages/CryptoPage'

import FoodTracking from './pages/FoodTracking'

import PrivateKey from './pages/lessons/Crypto/PrivateKey'
import WhatIsAWallet from './pages/lessons/Crypto/WhatIsAWallet'

type AppProps = {}
type AppState = {}


// TODO: get https://app.ens.domains/name/romi.eth/register
class App extends React.Component<AppProps, AppState> {
  render() {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <HomePage />
            } />
          <Route
            path="/cpp"
            element={
              <CppPage />
            } />
          <Route
            path="/cpp/simple"
            element={
              <SimpleProgram />
            } />
          <Route
            path="/cpp/variables"
            element={
              <SimpleProgram />
            } />
          <Route
            path="/cpp/functions"
            element={
              <SimpleProgram />
            } />
          <Route
            path="/crypto"
            element={
              <CryptoPage />
            }
            />
          <Route
            path="/crypto/what_is_a_private_key"
            element={
              <PrivateKey />
            }
            />
          <Route
            path="/crypto/what_is_a_wallet"
            element={
              <WhatIsAWallet />
            }
            />
          <Route
            path="/life/food"
            element={
              <FoodTracking />
            } />
          <Route
            path="*"
            element={
              <ErrorPage />
            } />
        </Routes>
      </Router>
    );
  }
}

export default App;
