import React from 'react';
import style from './index.module.scss';

interface OptionsType {
  timeZone: string
  timeZoneName: 'short'
  hour:  'numeric'
  minute: '2-digit',
}

type MacrosContainerProps = {
  title: string
  fat: number
  protien: number
  carbs: number
  timestamp: number
}
type MacrosContainerState = {}

class MacrosContainer extends React.Component<MacrosContainerProps, MacrosContainerState> {
  computeTime() {
    var date = new Date(0)
    date.setUTCSeconds(this.props.timestamp / 1000);

    var options: OptionsType = {
      timeZone: 'PST',
      timeZoneName: 'short',
      hour: 'numeric',
      minute: '2-digit'
    };
    return date.toLocaleTimeString('en-US', options);
  }

  render() {
    return (
      <div className={style.MacrosComponent}>
        <div className={style.MacrosTitle}>
          <div className={style.MacrosTitleValue}>
            {this.props.title}
          </div>
          <div className={style.MacrosTitleTime}>
            {this.computeTime()}
          </div>
        </div>
        <div>
          <div className={style.MacroComponent}>
            <div className={style.MacroLabel}>
              CALORIES: 
            </div>
            <div className={style.MacroValue}>
              <span className={style.MacroValueText}>{ Math.floor(this.props.fat * 9 + this.props.carbs * 4 + this.props.protien * 4)}</span>
            </div>
          </div>
          <div className={style.MacroComponent}>
            <div className={style.MacroLabel}>
              FAT: 
            </div>
            <div className={style.MacroValue}>
              <span className={style.MacroValueText}>{this.props.fat}</span>g
            </div>
          </div>
          <div className={style.MacroComponent}>
            <div className={style.MacroLabel}>
              CARBS: 
            </div>
            <div className={style.MacroValue}>
              <span className={style.MacroValueText}>{this.props.carbs}</span>g
            </div>
          </div>
          <div className={style.MacroComponent}>
            <div className={style.MacroLabel}>
              PROTIEN: 
            </div>
            <div className={style.MacroValue}>
              <span className={style.MacroValueText}>{this.props.protien}</span>g
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MacrosContainer;
