import React from 'react';
import style from './index.module.scss';

import Terminal from '../../components/Terminal'

import Console from '../../assets/terminal.svg'

type CppPageProps = {}
type CppPageState = {}

class CppPage extends React.Component<CppPageProps, CppPageState> {
  render() {
    return (
      <div className={style.CppPage}>
        <div
          className={style.HomeButton}
          onClick={() => { window.location.href = '/' }}
          >
          {'< HOME'}
        </div>
        <div className={style.CppContainer}>
          <div className={style.CppTitle}>
            C++ Page
          </div>
          <div className={style.CppTerminal}>
            <img src={Console} width={100} />
          </div>
          <div className={style.CppSummary}>
            Fundamentals...
          </div>
          <div className={style.CppMenuTitle}>
            <div>
              GETTING STARTED
            </div>
          </div>
          <div className={style.CppMenu}>
            <div
              className={style.CppSelect}
              onClick={() => { window.location.href = '/cpp/simple' }}>
              1. A simple program
            </div>
            <div
              className={style.CppSelect}
              onClick={() => { window.location.href = '/cpp/variables' }}>
              2. Variables
            </div>
            <div
              className={style.CppSelect}
              onClick={() => { window.location.href = '/cpp/functions' }}>
              3. Functions
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CppPage;
