import React from 'react';
import style from './index.module.scss';

import Terminal from '../../components/Terminal'


type ErrorPageProps = {}
type ErrorPageState = {}

class ErrorPage extends React.Component<ErrorPageProps, ErrorPageState> {
  componentDidMount() {
    document.addEventListener('keydown', this.escape);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escape);
  }

  escape = (e: Event) => {
    console.log("Hello")
    window.location.href = '/'
  }

  render() {
    return (
      <div className={style.ErrorPage}>
        <div>
          <div className={style.Title}>
            WINDOWS
          </div>
          <div className={style.Line}>
            An error has occured. To continue:
          </div>
          <div className={style.Line}>
            Don't go to links you aren't sure of:
          </div>
          <div className={style.Line}>
            You're welcome
          </div>
          <div className={style.AnyLineContinue}>
            Press any key to return to safety <span className={style.Blinking}>_</span>
          </div>
        </div>
      </div>
    )
  }
}

export default ErrorPage;