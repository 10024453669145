import React from 'react';
import style from './index.module.scss';

type SimpleProgramPageProps = {}
type SimpleProgramPageState = {}

class SimpleProgramPage extends React.Component<SimpleProgramPageProps, SimpleProgramPageState> {
  render() {
    return (
      <div>
        SimpleProgram
      </div>
    )
  }
}

export default SimpleProgramPage