import React from 'react';
import style from './index.module.scss';

import MacrosContainer from '../../components/MacrosContainer'

import Dot from '../../assets/dot.png'
import Pig from '../../assets/image.png'
import Heart from '../../assets/heart.jpg'
import Twitter from '../../assets/twitter.png'

interface OptionsType {
  weekday: 'short',
  year: 'numeric',
  month: 'long', 
  day: 'numeric',
  timeZone: string
}

interface FoodTrack {
  title: string
  fat: number
  carbs: number
  protien: number 
  timestamp: number
}

type FoodTrackingProps = {}
type FoodTrackingState = {
  stream: Array<FoodTrack>
}

// https://www.epochconverter.com/
class FoodTracking extends React.Component<FoodTrackingProps, FoodTrackingState> {
  state = {
    stream: [
      {
        title: "OATMEAL",
        fat: 2.5,
        carbs: 32,
        protien: 4, 
        timestamp: 1637957447000
      },
      {
        title: "PROTIEN SHAKE",
        fat: 2.5,
        carbs: 4,
        protien: 30, 
        timestamp: 1637954687000
      },
      {
        title: "GRANOLA BAR",
        fat: 7,
        carbs: 29,
        protien: 3, 
        timestamp: 1637959512000
      },
      {
        title: "PROTIEN SHAKE",
        fat: 2.5,
        carbs: 4,
        protien: 30, 
        timestamp: 1637960039000
      },
    ]
  }

  uniqueDates(): Array<string> {
    let options: OptionsType = {
      timeZone: 'PST',
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const dates = this.state.stream
        .sort((a, b) =>  (a.timestamp > b.timestamp) ? -1: 1)
        .map((x: any) => {
          var date = new Date(0)
          date.setUTCSeconds(x.timestamp / 1000)
          return date.toLocaleString('en-US', options);
        })

    return Array.from(new Set(dates))
  }

  uniqueItemsPerDate(date_set: Array<string>): Array<Array<FoodTrack>> {
    let options: OptionsType = {
      timeZone: 'PST',
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const mapped = date_set.map((x) => {
      const filtered_entities = this.state.stream
        .sort((a, b) => (a.timestamp > b.timestamp) ? -1: 1)
        .filter((v: any ) => {
          var date = new Date(0)
          date.setUTCSeconds(v.timestamp / 1000)
          return x == date.toLocaleString('en-US', options);
        })
      return filtered_entities;
    })

    return mapped
  }

  computeTotalCalories(food: Array<FoodTrack>): number {
    return food.map((x) => 
      x.carbs * 4 + x.protien * 4 + x.fat * 9
    ).reduce((a, b) => a + b, 0)
  }

  renderElements() {
    const unique_date = this.uniqueDates()
    const unique_items = this.uniqueItemsPerDate(unique_date)

    var c = unique_date.map((d, i) => {
      const items = unique_items[i]

      const macros_containers = items.map((x) => {
        return (
          <MacrosContainer
            title={x.title}
            fat={x.fat}
            carbs={x.carbs}
            protien={x.protien}
            timestamp={x.timestamp}/>
        )
      })
      return (
        <div className={style.MacrosMacros}>
          <div className={style.MacrosTitle}>
            <div className={style.Dots}>
              <img src={Dot} />
            </div>
            <div className={style.TitleValue}>
              {d}
            </div>
            <div className={style.CalorieValue}>
              { Math.floor(this.computeTotalCalories(items)) } calories
            </div>
          </div>
         {macros_containers}
        </div>
      )
    });
    return c
  }

  render() {
    return (
      <div className={style.FoodTracking}>
        <div className={style.HomeButton}
             onClick={() => { window.location.href = '/' }}>
          {'< HOME'}
        </div>
        <div className={style.Content}>
          <div className={style.MacrosCont}>
            <div className={style.Macros}>
              <div className={style.Timeline}>
                <div className={style.TimelineLineCont}>
                  <div className={style.TimelineLine}>
                  </div>
                </div>
              </div>
              <div>
                {this.renderElements()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FoodTracking