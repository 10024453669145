import React from 'react';
import style from './index.module.scss';

type WhatIsAWalletPageProps = {}
type WhatIsAWalletPageState = {}

class WhatIsAWalletPage extends React.Component<WhatIsAWalletPageProps, WhatIsAWalletPageState> {
  render() {
    return (
      <div className={style.WhatIsAWalletPage}>
        <div
          className={style.BackButton}
          onClick={() => { window.location.href = '/crypto' }}
          >
          {'< CRYPTO'}
        </div>
        <div className={style.WhatIsAWalletContainer}>
          <div className={style.WhatIsAWalletTitle}>
            What Is A Wallet??
          </div>
          <div className={style.WhatIsAWalletSummary}>
            Learn how Ethereum and Bitcoin wallet addresses are created.
          </div>
          <div>
            
          </div>
        </div>
      </div>
    )
  }
}

export default WhatIsAWalletPage